import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { getValidUrl } from '@/utils/getValidUrl'
import { RelatedProjects } from '@/components/RelatedProjects'
import { Img } from '@/components/Img'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import * as styles from './Profile.module.scss'

const Profile = ({
  bodyText,
  contactDetails,
  education,
  image,
  intro,
  name,
  notes,
  registrationsAndAffiliations,
  relatedProjects,
  role,
}) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const renderDetails = (
    <div className={styles.details}>
      {registrationsAndAffiliations && (
        <div>
          <h4 className={styles.details__heading}>
            Registrations / Affiliations
          </h4>
          <RichText>{registrationsAndAffiliations}</RichText>
        </div>
      )}
      {education && (
        <div>
          <h4 className={styles.details__heading}>Education</h4>
          <RichText>{education}</RichText>
        </div>
      )}
      {notes && <RichText>{notes}</RichText>}
    </div>
  )

  return (
    <div className={styles.Profile}>
      <Container indent={1}>
        <div className={styles.inner}>
          <div className={styles.side}>
            {image?.src && (
              <div className={styles.image}>
                <Img {...image} fillContainer />
              </div>
            )}
            {!isMobile && renderDetails}
          </div>
          <div className={styles.main}>
            <div>
              <h1 className={styles.name}>{name}</h1>
              <h2 className={styles.role}>{role}</h2>
            </div>
            {intro && (
              <div className={styles.intro}>
                <RichText headingStyles={false}>{intro}</RichText>
              </div>
            )}
            {bodyText && (
              <div className={styles.bodyText}>
                <RichText>{bodyText}</RichText>
              </div>
            )}
            {isMobile && renderDetails}
            {!!contactDetails?.length && (
              <div className={styles.contact}>
                {contactDetails.map((item) => {
                  let href = item.value
                  let label = item.value
                  let target = ''

                  if (item.type === 'email-address') {
                    href = `mailto:${item.value}`
                    label = 'Email'
                  } else if (item.type === 'phone-number' && item.value) {
                    href = `tel:${item.value.replace(/\s+/g, '')}`
                    label = 'Phone'
                  } else if (item.type === 'website') {
                    href = getValidUrl(item.value)
                    target = '_blank'
                    label = 'Website'
                  }

                  return (
                    <div
                      className={styles.contact__item}
                      key={item.id}
                      data-type={item.type}
                    >
                      <div className={styles.contact__label}>{label}</div>
                      <div className={styles.contact__value}>
                        <a href={href} target={target}>
                          {item.value}
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </Container>
      {!!relatedProjects?.length && (
        <RelatedProjects
          title="Selected Projects"
          projects={relatedProjects}
          background="white"
        />
      )}
    </div>
  )
}

Profile.propTypes = {
  bodyText: PropTypes.node,
  contactDetails: PropTypes.arrayOf(PropTypes.object),
  education: PropTypes.node,
  image: PropTypes.object,
  intro: PropTypes.node,
  name: PropTypes.string,
  notes: PropTypes.node,
  registrationsAndAffiliations: PropTypes.node,
  relatedProjects: PropTypes.array,
  role: PropTypes.string,
}

export default Profile
