import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getRichText } from '@/prismic/helpers/getRichText'
import { getSlug } from '@/utils/getSlug'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'
import { usePreviewData } from '@/prismic/hooks/usePreviewData'
import Profile from '@/templates/Profile'

const ProfilePrismic = ({ data }) => {
  const { prismicProfile, allPrismicProject } = usePreviewData(data)
  const profileData = prismicProfile.data

  // first create array of objects for all projects
  const allProjects = []
  allPrismicProject.edges.forEach(({ node }) => {
    const team = []
    node.data.team.forEach((teamMember) => {
      if (teamMember.profile.uid && teamMember.profile.uid !== null) {
        team.push(teamMember.profile.uid)
      }
    })

    allProjects.push({
      title: node?.data?.title,
      route: linkResolver(node),
      image: getImage(node.data?.image),
      team,
    })
  })

  // filter the all projects array where profile uid matches team
  const relatedProjects = allProjects.filter((project) =>
    project.team.includes(prismicProfile.uid)
  )

  // process contact details
  const contactDetails = []
  profileData.contact_details.forEach((item, i) => {
    if (item.value?.text) {
      contactDetails.push({
        value: item.value.text,
        type: getSlug(item.type),
        id: i + 1,
      })
    }
  })

  return (
    <Profile
      name={profileData.name}
      role={profileData.role}
      intro={getRichText(profileData.intro?.raw)}
      bodyText={getRichText(profileData.body_text?.raw)}
      registrationsAndAffiliations={getRichText(
        profileData.registrations_and_affiliations?.raw
      )}
      education={getRichText(profileData.education?.raw)}
      notes={getRichText(profileData.notes?.raw)}
      image={getImage(profileData.image)}
      contactDetails={contactDetails}
      relatedProjects={relatedProjects}
    />
  )
}

ProfilePrismic.defaultProps = {
  data: {},
}

ProfilePrismic.propTypes = {
  data: PropTypes.object,
}

export default ProfilePrismic

/*
# graphql filter is broken with gatsby-source-prismic 3.0.0-beta.14
# so we are pulling in all projects and filtering with js :(
allPrismicProject(
  filter: {
    data: { team: { elemMatch: { profile: { uid: { eq: $uid } } } } }
  }
*/

export const pageQuery = graphql`
  query ProfileByID($id: String!) {
    allPrismicProject {
      edges {
        node {
          uid
          type
          data {
            title
            team {
              profile {
                uid
              }
            }
            image {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
              url
              alt
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    }
    prismicProfile(id: { eq: $id }) {
      uid
      type
      data {
        name
        role
        intro {
          raw
        }
        body_text {
          raw
        }
        education {
          raw
        }
        registrations_and_affiliations {
          raw
        }
        notes {
          raw
        }
        image {
          fixed(width: 500) {
            ...GatsbyPrismicImageFixed_noBase64
          }
          url
          alt
        }
        contact_details {
          type
          value {
            text
          }
        }
      }
    }
  }
`
