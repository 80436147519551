// convert a url to a valid link
// https://stackoverflow.com/a/11300985

const IS_BROWSER = typeof window !== 'undefined'

export const getValidUrl = (url = '') => {
  let newUrl = IS_BROWSER ? window.decodeURIComponent(url) : url
  newUrl = newUrl.trim().replace(/\s/g, '')
  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }
  return newUrl
}
