// extracted by mini-css-extract-plugin
export var Profile = "Profile-module--Profile--+gxdK";
export var aniSpin = "Profile-module--ani-spin--1ExGS";
export var bodyText = "Profile-module--bodyText--HWNlA";
export var contact = "Profile-module--contact--j-LZo";
export var contact__item = "Profile-module--contact__item--4KZDL";
export var contact__label = "Profile-module--contact__label--xBlgk";
export var contact__value = "Profile-module--contact__value--gq3MH";
export var details = "Profile-module--details--jiC6U";
export var details__heading = "Profile-module--details__heading--ns9Rj";
export var image = "Profile-module--image--Nbje0";
export var inner = "Profile-module--inner--Ypkk5";
export var intro = "Profile-module--intro--KPCj5";
export var main = "Profile-module--main--WIA4i";
export var name = "Profile-module--name--cUPgN";
export var role = "Profile-module--role--vJm31";
export var side = "Profile-module--side--X2cNC";